@charset "UTF-8";
/*
* Это глобальные стили библиотеки (не переменные!). Их нужно заимпортить в свой проект (об этом в README.md)
*/
* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  font-style: normal;
}

/* Необходимо позаботиться о том, чтобы в вашей public лежали соответствующие fonts */
@font-face {
  font-family: "Roboto";
  font-weight: 400;
  src: url("/fonts/Roboto-Regular.woff");
}

@font-face {
  font-family: "Roboto";
  font-weight: 500;
  src: url("/fonts/Roboto-Medium.woff");
}

@font-face {
  font-family: "Roboto";
  font-weight: 600;
  src: url("/fonts/Roboto-Bold.woff");
}
