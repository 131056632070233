.popup {
  padding: 30px;
  color: #36474f;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 2px 6px 16px 0 rgba(54, 71, 79, 0.4);
  width: calc(100% - 40px);
  margin: auto;
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  z-index: 110;
  transform: translateY(-100%);
  background-color: #fff;
  opacity: 0;
  transition: all 0.2s ease-in;
  border-radius: 8px;
  box-sizing: border-box;
}

.popup__wrapper {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 110;
}

.popup__inner {
  height: 100%;
  width: 100%;
  position: relative;
  overflow: auto;
}

.popup:focus {
  outline: none;
}

.popup_large {
  max-width: 1208px;
}

.popup_medium {
  max-width: 916px;
}

.popup_small {
  max-width: 624px;
}

.popup_active {
  transform: translateY(-50%);
  opacity: 1;
}

.popup_isBiggerThanWindow {
  margin: 20px auto;
  top: 0;
  transform: translateY(0);
}

.popup_blockBodyOverflow {
  overflow: hidden;
}

.popup__close {
  margin: 10px;
  position: absolute;
  top: -30px;
  right: -30px;
}

.popup__title {
  color: #36474f;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.7px;
  margin-bottom: 20px;
}
